export const LAUNCHDARKLY_TOGGLES = {
  ACCOUNT_DISABLED: 'accountDisabled',
  ACCT_SETTINGS_REDESIGN: 'accountSettingsRedesign',
  APP_LIBRARY: 'appLibrary',
  ARCHIVE_VIDEO_TO_PLAYLISTS: 'archiveVideoToPlaylists',
  AUDIO_ONLY: 'audioOnly',
  MORE_UPLOAD_VIDEO_CODECS: 'moreUploadVideoCodecs',
  DECODER_REMOTE_LOAD: 'decoderRemoteLoad',
  ENCODER_AUTO_INPUT: 'encoderAutoInput',
  ENCODER_CUSTOMER_UPDATE: 'encoderCustomerUpdate',
  ENCODER_4K_INPUT: 'encoder4KInput',
  HOTSPOTS_LABEL: 'hotspotsLabel',
  OBS_VISIBLE: 'obsVisible',
  OFFLINE_VIDEO: 'offlineVideo',
  QR_CODE_RECOGNITION: 'qrCodeRecognition',
  SAVED_VIDEO_CUES: 'savedVideoCues',
  SIMPLE_ENCODER_PRESETS: 'simpleEncoderPresets',
  STUDIO_ANALYTICS_YOUTUBE: 'studioAnalyticsYoutube',
  STUDIO_UIUX: 'studioUiux',
  SYNC_EVENT_DETAILS: 'syncEventDetails',
  TRANSCODED_DOWNLOADS: 'transcodedDownloads',
  TRIM_VIDEO: 'trimVideo',
  UDG_SCHEDULING: 'udgScheduling',
  UNIVERSAL_DESTINATION_GROUPS: 'universalDestinationGroups',
} as const;
