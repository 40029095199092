export namespace Get {
  export type FetchOptions =
    | {
        force?: boolean;
        uuid?: string;
      }
    | undefined;

  export type UsageSummary = {
    concurrentWebBroadcasts: number;
    enterpriseLicenses: number;
    inUseEnterpriseLicenses: number;
    inUseLanCloudSiteLicenses: number;
    inUseNonProductionSiteLicenses: number;
    inUseProfessionalLicenses: number;
    inUseSoftwareEncoders: number;
    lanCloudSiteLicenses: number;
    nonProductionSiteLicenses: number;
    professionalLicenses: number;
    softwareEncoderLicenses: number;
    webBandwidth: number;
  };

  export type CustomerWebPlan = {
    allowDownload: boolean;
    allowQrCodeDetection: boolean;
    allowSimLive: boolean;
    allowStreamUrls: boolean;
    allowVideoUpload: boolean;
    forceBranded: boolean;
    name: string;
    numberOfSocialMediaDestinations: number;
    streamMinutes: number | null;
    uuid: string;
    viewerMinutes: number | null;
  };

  export type SubscriptionDetails = {
    additionalStreamMinutes: number;
    additionalViewerMinutes: number;
    concurrentRtmpStreams: number;
    contentLibraryTrialCancelled: boolean;
    contentLibraryTrialEndTimestamp: string | null;
    contentLibraryTrialPlanId: string | null;
    contentLibraryTrialStatus: TrialStatus | null;
    subtitlesHoursPerMonth: number;
    uuid: string;
  };

  export type Customer = {
    active: boolean;
    additionalConcurrentWebEvents: number;
    additionalVodBandwidthGb: number;
    additionalVodStorageGb: number;
    additionalWebDataGb: number;
    allowed16ChannelAudio: boolean;
    billingYearlyMultiSite: boolean;
    billingYearlyVod: boolean;
    billingYearlyWeb: boolean;
    defaultWebEventProfileType: string | null;
    destinationGroupVersion: number | null;
    managedBy: string | null;
    masterEcg: string | null;
    maxSites: number;
    msa: boolean;
    multisitePlanId: string | null;
    multisitePlanName: string | null;
    multisiteStartDate: string | null;
    name: string;
    notes?: string;
    numConcurrentTranscodes: number;
    planName: string | null;
    regionId: string;
    subscriptionDetails: SubscriptionDetails;
    usageSummary: UsageSummary;
    uuid: string;
    version: number;
    vodPlan: VodPlan | null;
    vodPlanId: string | null;
    vodPlanName: string | null;
    vodStartDate: string | null;
    webBandwidth: number;
    webMaxDeleteAfter: number | null;
    webPlan: CustomerWebPlan | null;
    webPlanId: string | null;
    webPlanName: string | null;
    webStartDate: string | null;
  };

  export const TRIAL_STATUS = {
    IN_TRIAL: 'in_trial',
    CANCELLED: 'cancelled',
    ENDED: 'ended',
  } as const;
  export type TrialStatus = (typeof TRIAL_STATUS)[keyof typeof TRIAL_STATUS];

  export type Toggle = {
    description: string;
    name: string;
    uuid: string;
  };

  export type TogglesRegistered = {
    toggleIds: string[] | null;
    users: [{ toggleIds: string[]; userId: string; userName: string; userPlanId: string }];
  };

  export type VodPlan = {
    allowVodex: boolean;
    apiRequestsPerMinute: number;
    name: string;
    storageCapGB: number;
    uuid: string;
  };
}
